<template>
  <div>
    <div class="kit__utils__heading">
      <SearchBar
        :title="title"
        :placeholder="placeholder"
        :showSettings="showSettings"
        :settingsPath="settingsPath"
        @search="updateSearchQuery"
      />
    </div>
    <div class="card">
      <div class="card-body">
        <list :list="filteredData" :target="target"/>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/custom/visual/SearchBar'
import list from '@/components/custom/visual/lists/List2'

export default {
  components: {
    list,
    SearchBar,
  },
  props: {
    title: String,
    placeholder: String,
    showSettings: Boolean,
    settingsPath: String,
    listData: Array,
    target: String,
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    filteredData() {
      if (!this.searchQuery) {
        return this.listData
      }
      return this.listData.filter((data) => JSON.stringify(data).toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
    },
  },
  methods: {
    updateSearchQuery(query) {
      const value = query.target.value
      this.searchQuery = value
    },
  },
}
</script>
