<template>
  <div>
    <ul class="list-unstyled">
      <li :class="$style.item" v-for="item in list" :key="item.id">
        <a @click="toTarget(item.id)" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <!-- <i :class="item.icon"/> -->
            <span>{{ item.icon }}</span>
          </div>
          <div class="mr-2">
            <div>{{ item.title }}</div>
            <div class="text-muted">
              {{ item.subtext }}
            </div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <!-- <li :class="$style.item">
         <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-mail"/>
          </div>
          <div class="mr-2">
            <div>Message Removed</div>
            <div class="text-muted">2 hours ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-grid"/>
          </div>
          <div class="mr-2">
            <div>Parcel Received</div>
            <div class="text-muted">6 hours ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-database"/>
          </div>
          <div class="mr-2">
            <div>Parcel Recived</div>
            <div class="text-muted">15 hours ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-flag"/>
          </div>
          <div class="mr-2">
            <div>User Activated</div>
            <div class="text-muted">2 days ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'KitList5',
  props: ['list', 'target'],
  methods: {
    moment,
    toTarget(id) {
      const destination = `${this.target}${id}`
      console.log(destination)
      this.$router.push({ path: destination })
    },
  },
}
</script>
<style lang="scss" module>
  @import './style.module.scss';
</style>
