<template>
  <div class="row d-flex align-items-center">
    <div class="col-md-4">
      <h4 class="flex-grow-1">{{ title }}</h4>
    </div>
    <div class="col-md-8 d-flex justify-content-end align-items-center">
      <a-input :placeholder="placeholder" @change="search" v-model="searchText" style="max-width: 400px"/>
      <div v-if="showSettings">
        <a-button @click="navigateToSettings">
          <i class="fa fa-cog"></i>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    placeholder: String,
    showSettings: Boolean,
    settingsPath: String,
  },
  data() {
    return {
      searchText: '',
    }
  },
  methods: {
    search(e) {
      this.$emit('search', e)
    },
    navigateToSettings() {
      this.$router.push({ path: this.settingsPath })
    },
  },
}
</script>
